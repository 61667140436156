import { createRouter, createWebHistory,createWebHashHistory } from "vue-router";
// import Home from '@/components/HelloWorld.vue'
// import About from '../views/About.vue'
// import List from '../views/List.vue'

const routes = [
  {
    path: "/",
    component: () => import("@/components/index.vue"),
  },
  // 关于我们
  {
    path: "/about",
    component: () => import("@/components/about.vue"),
  },
  // 课程服务
  {
    path: "/courseServices",
    component: () => import("@/components/courseServices.vue"),
  },
  // 学员成果
  {
    path: "/studentaChievements",
    component: () => import("@/components/studentaChievements.vue"),
  },
  // 讲师介绍
  {
    path: "/aboutLnstructor",
    component: () => import("@/components/aboutLnstructor.vue"),
  },
  // 资讯中心
  {
    path: "/informationCenter",
    component: () => import("@/components/informationCenter.vue"),
  },
    // 投诉
    {
      path: "/complaints",
      component: () => import("@/components/complaints.vue"),
    },
        // 详情
        {
          path: "/newDetail",
          component: () => import("@/components/newDetail.vue"),
        },
  // {
  //   path:'/about',
  //   component: () => import('@/components/adout.vue'),
  // }
  // {
  //   path:'/list',
  //   component:List
  // }
];
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
export default router;
