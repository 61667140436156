import { createApp } from 'vue'
import App from './App.vue'
import router from './route'
import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/css/index.less'

const app = createApp(App)
import { Swipe, SwipeItem } from 'vant';

app.use(Swipe);
app.use(SwipeItem);
// let baseURL = '/api'
let baseURL = ''
axios.defaults.baseURL = baseURL
app.config.globalProperties.$axios = axios
axios.defaults.timeout = 30000;


app.use(router) //注册路由
app.use(ElementPlus) 
app.mount('#app')
// createApp(App).mount('#app')
